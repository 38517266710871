import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable }  from 'rxjs/internal/Observable';
import { of }  from 'rxjs/internal/observable/of';
import { EMPTY }  from 'rxjs/internal/observable/empty';
import { tap, catchError, finalize, switchMap }  from 'rxjs/operators';
import { LoaderService } from './common/loader.service';


export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: any;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
    showSpinner?: boolean;
}

const defaultOptions: IRequestOptions = {
    showSpinner: true,
}


@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(private loaderService: LoaderService, public http: HttpClient) { }

    public get<T>(url: string, options?: IRequestOptions, loadingSpinner: boolean = true, propagateError: boolean = false): Observable<T> {
        if (loadingSpinner) {
            this.loaderService.show();
        }
        
        options = {
            ...defaultOptions,
            ...options
        };

        return of({}).pipe(
            tap(x => this.onStart(options)),
            switchMap(x => this.http.get<T>(url, options)),
            catchError((error, caught) => this.onCatch()),

            tap((res: any) => { },
            (error: any) => {
                this.onError(error);
            }),
            finalize(() => {
                if (loadingSpinner !== false) {
                    this.loaderService.hide();
                }
                this.onEnd(options);
            }),
        )
    }

    public post<T>(url: string, params: any, options?: IRequestOptions,
        propagateError: boolean = false): Observable<T> {
        options = {
            ...defaultOptions,
            ...options
        };
        
        return of({}).pipe(
            tap(x => this.onStart(options)),
            switchMap(x => this.http.post<T>(url, params, options)),
            catchError((error, caught) => {
                throw error;
                // if(!propagateError){
                //     return this.onCatch()
                // }else{
                //     throw error;
                // }
            }),
            tap((res: any) => { },
                (error: any) => {
                    this.onError(error);
                }),
            finalize(() => {
                this.onEnd(options);
            }),
        )
    }

    private onCatch() {
        return EMPTY;
    }

    private onError(res: any): void {
        console.log('Error, status code: ' + res.status);
    }

    private onStart(options?: IRequestOptions) {
        options && options.showSpinner && this.loaderService.show();
    }

    private onEnd(options?: IRequestOptions): void {
        options && options.showSpinner && this.loaderService.hide();
    }
}


