import { Injectable } from '@angular/core';
import { Observable }  from 'rxjs/internal/Observable';
import { BehaviorSubject }  from 'rxjs/internal/BehaviorSubject';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '../sso';

@Injectable({
    providedIn: 'root'
})
export class AccountMemberService {
    private accountMemberSettingsSubject = new BehaviorSubject<AccountMemberSettings | null>(null);
    readonly accountMemberSettings: Observable<AccountMemberSettings | null> = this.accountMemberSettingsSubject.asObservable();
    public memberDetails: AccountMemberSettings | null = null;
    private memberPermissions: string[] | null;
    loading: boolean;

    constructor(private http: HttpService,
                private authSvc: AuthService) {
        this.loading = true;
        this.resetSettings();
    }

    resetSettings() {
        let userDetails = this.authSvc.user();
        this.memberDetails = userDetails;
        this.memberPermissions = userDetails.permissions;
        this.accountMemberSettingsSubject.next(this.authSvc.user());
        this.loading = false;
    }

    checkPermissionLevel(permission: string): boolean {

        return true; //Full permissions for dark release

        // if (!this.memberPermissions) {
        //     return false;
        // }
        // return this.memberPermissions && this.memberPermissions.indexOf(permission) !== -1;
    }

    checkPermissionAnyLevels(permissions: string[]): boolean {
        return true; //Full permissions for dark release

        // let result = false;
        // if (permissions != null && permissions.length > 0) {
        //     permissions.forEach((permission) => {
        //         result = result || this.checkPermissionLevel(permission);
        //     });
        // }
        // return result;
    }

    checkPermissionAllLevels(permissions: string[]): boolean {
        return true; //Full permissions for dark release

        // let result = true;
        // if (permissions != null && permissions.length > 0) {
        //     permissions.forEach((permission) => {
        //         result = result && this.checkPermissionLevel(permission);
        //     });
        // }
        // return result;
    }

    logOut() {
        
    }
}

export interface AccountMemberSettings {
    accountInfoId: number;
    memberId: number;
    memberName: string;
    memberRole: string;
    permissions: string[];
}

export interface AuthenticateRequest {
    accountInfoId: string;
    memberId: string;
    memberPermissions: string[];
}

export interface AuthenticatedResponse {
    token: string;
    refreshToken: string;
}

export interface Token {
    token: string;
}