import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import { AuthService } from '@core/services/sso/auth.service';
import { environment } from 'src/environments/environment';

export const authGuard: CanActivateFn = (route, state) => {
  if (!inject(AuthService).isAuthenticated()) {
    // inject(Router).navigate([environment.rethinkBHUrl]);
    window.location.href = environment.rethinkBHUrl;
    return false;
  }
  return true;
};