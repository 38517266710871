<nav class="navbar navbar-expand-lg navbar-transparent fixed-top">
  <div class="container-fluid">
      <div class="navbar-wrapper">
        <a class="navbar-brand" href="/#/dashboard">{{getTitle()}}</a>
      </div>
      <button mat-raised-button class="navbar-toggler" type="button" >
          <span class="sr-only">Toggle navigation</span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
          <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navigation">
          <form class="navbar-form">
              <div class="input-group no-border">
                  <input type="text" value="" class="form-control" placeholder="Search...">
                  <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                      <i class="material-icons">search</i>
                      <div class="ripple-container"></div>
                  </button>
              </div>
          </form>
          <ul class="navbar-nav">
              <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)">
                      <i class="material-icons">dashboard</i>
                      <p>
                          <span class="d-lg-none d-md-block">Stats</span>
                      </p>
                  </a>
              </li>
              <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="material-icons">notifications</i>
                      <span class="notification">5</span>
                      <p>
                          <span class="d-lg-none d-md-block">Some Actions</span>
                      </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                      <a class="dropdown-item" href="javascript:void(0)">Mike John responded to your email</a>
                      <a class="dropdown-item" href="javascript:void(0)">You have 5 new tasks</a>
                      <a class="dropdown-item" href="javascript:void(0)">You're now friend with Andrew</a>
                      <a class="dropdown-item" href="javascript:void(0)">Another Notification</a>
                      <a class="dropdown-item" href="javascript:void(0)">Another One</a>
                  </div>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0)">
                      <i class="material-icons">person</i>
                      <p>
                          <span class="d-lg-none d-md-block">Account</span>
                      </p>
                  </a>
              </li>
          </ul>
      </div>
  </div>
</nav>

