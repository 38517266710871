<div class="publicHeader sidebar">

    <div id="new-side-bar" class="slide">
        <div class="new-side-bar-items">
            <div class="d-flex flex-column justify-content-start">


                <div id="menu-group-wrapper" class="small-9med menu">
                    <div _ngcontent-ppb-c425="" class="menu-group ng-star-inserted" style="display: inline;">
                        <div _ngcontent-ppb-c425="" style="display: inline;" class="ng-star-inserted"><!----><a
                                _ngcontent-ppb-c425="" routerlinkactive="activeArea"
                                class="menu-item-wrapper flex-column ng-star-inserted" href="/#/add-claim">
                                <div _ngcontent-ppb-c425="" class="top-menu-icon-wrapper ng-star-inserted">
                                    <div _ngcontent-ppb-c425="" class="top-menu-icon client-icon" title="Clients"></div>
                                </div><!----><!---->
                                <div _ngcontent-ppb-c425="" style="align-self: center;">Claims</div>
                            </a><!----><!----></div><!---->
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>