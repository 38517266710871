import { Injectable } from '@angular/core';
import { HttpService } from '..';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SSOService {
    constructor( private http: HttpService) {
    }

    theraweSSOService(childProfileId?: number): Observable<any> {
        let param = (childProfileId == null) ? {}: childProfileId;
        return this.http.post('/core/api/common/sso/OpenTherawe', param);
    };

}