<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer *ngIf="isShowSideNav()" [attr.role]="!(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">

    <div fxHide.xs class="BH-logo">
      <img src="/assets/img/BHLogo_45x45.png" alt="Rethink Healthcare logo">
    </div>

    <div *ngFor="let item of sideMenus" class="menuTile" [routerLinkActive]="['is-active']" routerLink="{{item.path}}">
      <div class="menu" style="text-align: center;">
        <div class="top-menu-icon-wrapper">
          <div class="top-menu-icon menuIcon icon-24 {{item.leftSideIcon}}" title="{{item.Name}}"></div>
        </div>
        <div class="menuText">{{item.Name}}</div>
      </div>
    </div>

  </mat-sidenav>


  <mat-sidenav-content>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


