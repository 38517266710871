import {DestroyRef, Injectable, signal, WritableSignal} from '@angular/core';
import {HttpClient, HttpContext} from "@angular/common/http";
import {Router} from "@angular/router";
import {catchError, Observable, of, tap} from "rxjs";
import {JwtHelperService} from "@auth0/angular-jwt";
import { environment } from 'src/environments/environment';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import { AccountMemberSettings, AuthenticatedResponse, Token } from '../account/account-member.service';
import { IS_PUBLIC } from '../auth.interceptor';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Token will be refreshed 1 min before expiration time
  private readonly TOKEN_EXPIRY_THRESHOLD_MINUTES = 1;
  private readonly CONTEXT = {context: new HttpContext().set(IS_PUBLIC, true)};
  private apiAuthUrl = environment.authApiBaseUrl;
  public isLoading=false;

  get user(): WritableSignal<AccountMemberSettings | null> {
    const token = localStorage.getItem('token');
    return signal(token ? this.getUserData(this.jwtHelper.decodeToken(token)) : null);
  }

  getUserData(decodedToken): AccountMemberSettings {
    return {
      accountInfoId: parseInt(decodedToken.AccountInfoId),
      memberId: parseInt(decodedToken.MemberId),
      memberName: decodedToken.MemberName,
      memberRole: decodedToken.MemberRole,
      permissions: [] //decodedToken.memberPermissions
    }
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private destroyRef: DestroyRef
  ) {
  }

  isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  refreshToken(): Observable<AuthenticatedResponse | null> {
    const billing_token = localStorage.getItem('token');
    if (!billing_token) {
      return of();
    }
    let billingToken: Token = {
      token: billing_token
    }

    return this.http.post<AuthenticatedResponse>(
      this.apiAuthUrl + '/SSO/Refresh', billingToken, this.CONTEXT)
      .pipe(
        catchError(() => of()),
        tap(data => {
          const loginSuccessData = data as AuthenticatedResponse;
          this.storeTokens(loginSuccessData);
          this.scheduleTokenRefresh(loginSuccessData.token);
        })
      );
  }

  login(rethink_Token: any): Observable<AuthenticatedResponse | null> {
    if (!rethink_Token) {
      return of();
    }
    let rethinkToken: Token = {
      token: rethink_Token
    }
    return this.http.post<AuthenticatedResponse>(this.apiAuthUrl + '/SSO/SSOLogin', rethinkToken, this.CONTEXT)
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            // Handle invalid credentials
            console.log('Invalid credentials');
          }
          return of();
        }),
        tap(data => {
          console.log(data);
          const loginSuccessData = data as AuthenticatedResponse;
          this.storeTokens(loginSuccessData);
          this.scheduleTokenRefresh(loginSuccessData.token);
          this.router.navigate(['/billing']);
        })
      );
  }

  scheduleTokenRefresh(token: string): void {
    const expirationTime = this.jwtHelper.getTokenExpirationDate(token)?.getTime();
    const refreshTime = expirationTime ? expirationTime - this.TOKEN_EXPIRY_THRESHOLD_MINUTES * 60 * 1000 : Date.now();
    const refreshInterval = refreshTime - Date.now();

    if (refreshInterval > 0) {
      setTimeout(() => {
        this.refreshToken()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe();
      }, refreshInterval);
    }
    }

  logout(): void {
    // Not required since we are not associating a user with JWT token in DB
    // const refreshtoken = localStorage.getItem('refreshtoken');
    // this.http.post<LoginResponse>(`${environment.authApiBaseUrl}/SSO/invalidate`, {refreshtoken}, this.CONTEXT)
    //   .pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe(() => {
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('refreshtoken');
    //     window.location.href = environment.rethinkBHUrl;
    //   });

      localStorage.removeItem('token');
      localStorage.removeItem('refreshtoken');
      window.location.href = environment.rethinkBHUrl + '/Healthcare#/Main';
      this.isLoading=false
  }

  storeTokens(data: AuthenticatedResponse): void {
    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshtoken', data.refreshToken);
  }
}