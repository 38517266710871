import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountMemberService } from '@core/services/account/account-member.service';
import { AccountPermissions } from '@core/enums/account';
import { SSOService } from '@core/services/sso';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AppRouteChangeService } from '@core/services/common';
import { AccountMemberSettings } from '@core/services/account/account-member.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/add-claim', title: 'Create Claim',  icon:'person', class: '' },
    { path: '/table-list', title: 'Table List',  icon:'content_paste', class: '' },
    { path: '/typography', title: 'Typography',  icon:'library_books', class: '' },
    { path: '/icons', title: 'Icons',  icon:'bubble_chart', class: '' },
    { path: '/maps', title: 'Maps',  icon:'location_on', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
    { path: '/upgrade', title: 'Upgrade to PRO',  icon:'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent  implements OnInit, OnDestroy {
  public childProfileId: number | null = null;
  public accountMemberSettings: AccountMemberSettings|null;
  private unsubscribe = new Subject<void>();
  public isHealthcare = false;
  public isEducation = false;
  public modules: any = [];
  public userisLoggedIn = false;
  showSubMenu = false;
  public _url = "";
  iconOpen = false;
  showUserName = false;

  defaultIcon = '/Images/HealthCare/Public/defaultIcon.png';
  userIcon: any = null;
  currentRouteData: any;
  showRethinkBilling: boolean;
  showKareo: boolean;
  showSetss: boolean;
  staffId: number | null;
  routeName: string;
  showMenu: boolean;
  showLoginMenu: boolean;
  clientId: number;
  selectedClient: any;

  /** child-top-menu ctor */
  constructor(
      private router: Router,
      private accountService: AccountMemberService,
      private ssoService: SSOService,
      private dialogService: DialogService,
      private routeService: AppRouteChangeService,
      //private childPickerService: ChildPickerService,
  ) {}

  logOut() {
      this.accountService.logOut();
  }

  ngOnInit() {
    //   if (this.accountMemberSettings) {
    //       this.checkPermission();

    //       this.modules = [];
    //       this.isHealthcare = false;
    //       if (this.accountMemberSettings) {
    //           if (this.accountMemberSettings.isHealthcare) {
    //               this.isHealthcare = true;
    //               this.modules = this.hcModules;
    //           } else if (this.accountMemberSettings.isEmployeeBenefit) {
    //               this.isEducation = true;
    //               this.modules = this.ebModules;
    //           } else {
    //               this.isEducation = true;
    //               this.modules = this.organizationalModules;
    //           }
    //       }
    //   }
  }

  ngOnDestroy() {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }

  navigate(module: any) {
      this.modules.each((item: any) => { item.selected = false; });
      if (!module.isDisabled) {
          if (module.isNewVersion === true) {
              this.router.navigate(module.href);
          } else {
              window.location.href = module.href;
          }

          module.selected = true;
      }
  };

  toggleSubItems(item: any, count: number) {
      item.viewSubItems = !item.viewSubItems;

      item.height = (item.viewSubItems ? count * 50 : 0) + 'px';
      return item.viewSubItems;
  }
  prevHref = window.location.href.replace(window.location.origin, '').replace('/core', '');
  openIfThisActive(href: string) {
      if (this.prevHref.startsWith(href)) {
          this.showSubMenu = !this.showSubMenu;
      } else {
          this.showSubMenu = false;
      }

      this.prevHref = href;
  }

  hasIcon() {
      return !!this.userIcon;
  }

  toggleUserDropdown(forceClose: boolean) {
      this.iconOpen = forceClose ? false : !this.iconOpen;
      const dropdown = document.getElementById('user-dropdown-list');
      const dropdownMenu = document.getElementById('user-dropdown-menu');
      if (dropdown && dropdownMenu) {
          dropdown.classList.toggle('active', this.iconOpen);
      }
  }


  OpenTherawe() {
      this.ssoService.theraweSSOService().subscribe((result) => {
          window.open(result, "_blank");
      });
  }

//   GetTheraweHoverText() {
//       if (!this.userisLoggedIn)
//           return '';


//       if (this.accountMemberSettings && this.accountMemberSettings.isParentUser) {
//           return 'Connect with Clinician';
//       };


//       //if (SelectedClient != null) {
//       //    return 'Engage with ' + SelectedClient.FirstName;
//       //}

//       return 'Engage with Parent';

//   };



  initCheck = true;
  checkPermission() {
      this.providerAccountMenu();
  }

  providerAccountMenu() {
      const canView = this.accountService.checkPermissionLevel(AccountPermissions.ProviderAccountView);
      if (canView && this.initCheck) {
          this.initCheck = false;
      }
  }

  clickOutside() {
      this.toggleUserDropdown(true);
  }

  openTheraweforChild() {
      if (this.clientId) {
          this.ssoService.theraweSSOService(this.clientId).pipe(takeUntil(this.unsubscribe)).subscribe((result) => {
              window.open(result, "_blank");
          });
      }
  }

  isShowTheraweLinkForChildProfile() {
      if (this.isAccountHasTheraweAccess)
          return true;

      return false;
  }

  isAccountHasTheraweAccess = false;
//   GetTheraweHoverTextForChildProfile() {
//       if (this.accountMemberSettings != null && this.accountMemberSettings.isParentUser == true) {
//           return 'Connect with Clinician';
//       };

//       if (this.selectedClient != null)
//           return 'Engage with ' + this.selectedClient.firstName + "'s parent(s)";


//       return 'Engage with Parent';

//   };
}