import { Injectable } from '@angular/core';
import { BehaviorSubject }  from 'rxjs/internal/BehaviorSubject';


@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private readonly loaderSubject = new BehaviorSubject<boolean>(false);
    private numLoadings = 0;

    loaderState = this.loaderSubject.asObservable();

    show() {
        this.numLoadings++;
        this.loaderSubject.next(true);
    }

    hide() {
        if ((--this.numLoadings) <= 0) {
            this.numLoadings = 0;
            this.loaderSubject.next(false);
        }
    }

    isLoading() {
        return this.loaderSubject.getValue();
    }
}