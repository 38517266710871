import { AfterViewInit, Component, DestroyRef, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@core/services/sso/auth.service';
import { AuthenticatedResponse } from '@core/services/account/account-member.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';

export const ROUTES: any[] = [
  { path: '/add-claim', title: 'Create Claim',  icon:'person', class: '' },
  { path: '/encounter-list', title: 'Table List',  icon:'content_paste', class: '' },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewInit {
  title = 'RethinkBilling';
  private listTitles: any[];
  opened: boolean = true;
  isLogin: boolean;
  contentMargin = 240;
  sideMenus: any[] = ROUTES;
  displayLoader: any = false;
  invalidLogin: boolean;
  rethinkUrl: string;
  params: Params;
  // idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  
  constructor(private location: Location,
    private authSvc: AuthService,
    private router: Router,
    private destroyRef: DestroyRef,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private idle: Idle, private keepalive: Keepalive
    ) {
      // sets an idle timeout of 10 minutes, for testing purposes.
    idle.setIdle(600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => { 
    //   this.idleState = 'No longer idle.'
    //   console.log(this.idleState);
    //   this.reset();
    // });
    
    idle.onTimeout.subscribe(() => {
      // this.idleState = 'Timed out!';
      this.timedOut = true;
      this.authSvc.logout();
      // console.log(this.idleState);
    });
    
    // idle.onIdleStart.subscribe(() => {
    //     this.idleState = 'You\'ve gone idle!'
    //     console.log(this.idleState);
    // });
    
    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   this.idleState = 'You will time out in ' + countdown + ' seconds!'
    //   console.log(this.idleState);
    // });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
        return false;
    }
    return true;
};

  ngOnInit() {

    // Step 1: Retrieve Rethink token from query string
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let rethink_Token = params['token'];
      if (rethink_Token !== undefined){
        //Step 2: Create JWT token
        //Step 2A: Call Rethink API to validate token & receive user data
        this.authSvc.login(rethink_Token).pipe(
          takeUntilDestroyed(this.destroyRef)
          
        ).subscribe();
        this.listTitles = ROUTES.filter(listTitle => listTitle);
      }

      //Hack for local debugging & lower env
      let testmode = params['testmode'];
      if (testmode !== undefined){
        let authResponse: AuthenticatedResponse = {
          token: environment.token,
          refreshToken: 'sDjdWYn8/+cWt59Pa2QDzQ71879PQg18pyoQU0dxmHU='
        }
        this.authSvc.storeTokens(authResponse);
        this.authSvc.scheduleTokenRefresh(authResponse.token);
        this.router.navigate(['/billing']);
        this.listTitles = ROUTES.filter(listTitle => listTitle);
      }
    });

    //Sample response from Rethink
    // let userData: any = {
    //   "accountInfoId": 18421,
    //   "memberId": 105815,
    //   "memberName": "HealthCare_010815 HealthCare_010815",
    //   "memberRole": "Role 4a",
    //   "permissions": {
    //       "billingView": true,
    //       "billingEdit": true,
    //       "billingEditApprovedAppointments": false,
    //       "billingApprove": true,
    //       "billingSubmitClaims": false,
    //       "billingPostPayments": true,
    //       "billingReopenEncounter": true,
    //       "billingCloseEncounters": true
    //   }
    // };
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngAfterViewInit() {
    
  }

  onMouseHover(){
    this.opened = true;
  }

  onClick(){
    this.opened = !this.opened;
    // if (!this.opened) this.contentMargin = 70; else this.contentMargin = 240;
  }

  reset() {
    this.idle.watch();
    // this.idleState = 'Started.';
    this.timedOut = false;
  }

  isUserAuthenticated = (): boolean => {
    return this.authSvc.isAuthenticated();
  }

  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return 'Dashboard';
  }

}
