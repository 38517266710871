export enum AccountPermissions {
    AccountSetupAddEditStudent = 'AccountSetupAddEditStudent',
    AccountSetupAddEditTeamMember = 'AccountSetupAddEditTeamMember',
    AccountSetupAssign = 'AccountSetupAssign',
    AccountSetupDeleteArchiveStudent = 'AccountSetupDeleteArchiveStudent',
    AccountSetupDeleteTeamMember = 'AccountSetupDeleteTeamMember',
    AccountSetupView = 'AccountSetupView',
    ActivityCenterAdd = 'ActivityCenterAdd',
    ActivityCenterDelete = 'ActivityCenterDelete',
    ActivityCenterPlay = 'ActivityCenterPlay',
    ActivityCenterViewDemo = 'ActivityCenterViewDemo',
    AddStaffToClient = 'AddStaffToClient',
    AdminDashboardEdit = 'AdminDashboardEdit',
    AdminDashboardEditSave = 'AdminDashboardEditSave',
    AdminDashboardView = 'AdminDashboardView',
    AnalyzeDataDelete = 'AnalyzeDataDelete',
    AnalyzeDataEdit = 'AnalyzeDataEdit',
    AnalyzeDataRun = 'AnalyzeDataRun',
    ApplyVbMappLicense = 'ApplyVbMappLicense',
    AssessmentEdit = 'AssessmentEdit',
    AssessmentShare = 'AssessmentShare',
    AssessmentView = 'AssessmentView',
    BehaviorSupportAddEdit = 'BehaviorSupportAddEdit',
    BehaviorSupportView = 'BehaviorSupportView',

    SchedulerEditApprovedAppointments = 'SchedulerEditApprovedAppointments',

    BillingView = 'BillingView',
    BillingAddEditApproveSubmitClaims = 'BillingAddEditApproveSubmitClaims',
    BillingViewEditEncounters = 'BillingViewEditEncounters',
    BillingWriteOffClaims = 'BillingWriteOffClaims',
    BillingDeleteClaims = 'BillingDeleteClaims',
    BillingViewPayments = 'BillingViewPayments',
    BillingAddEditDeletePayments = 'BillingAddEditDeletePayments',

    ClassroomScheduleAddEdit = 'ClassroomScheduleAddEdit',
    ClassroomScheduleView = 'ClassroomScheduleView',
    ClientAccessAllLocations = 'ClientAccessAllLocations',
    ClientAccessAssignedOnly = 'ClientAccessAssignedOnly',
    ClientAccessUserLocation = 'ClientAccessUserLocation',
    ClientAccountAdd = 'ClientAccountAdd',
    ClientAccountDelete = 'ClientAccountDelete',
    ClientAccountEdit = 'ClientAccountEdit',
    ClientAccountView = 'ClientAccountView',
    ClientNotesAddEdit = 'ClientNotesAddEdit',
    ClientNotesDelete = 'ClientNotesDelete',
    ClientNotesView = 'ClientNotesView',
    ClientReportTemplatesDelete = 'ClientReportTemplatesDelete',
    ClientReportTemplatesEdit = 'ClientReportTemplatesEdit',
    ClientReportTemplatesView = 'ClientReportTemplatesView',
    ClientAuthorizationsView = 'ClientAuthorizationsView',
    ClientAuthorizationsEdit = 'ClientAuthorizationsEdit',
    ClientAuthorizationsDelete = 'ClientAuthorizationsDelete',
    DataEntryDeleteOthers = 'DataEntryDeleteOthers',
    DataEntryEdit = 'DataEntryEdit',
    DataEntryReorder = 'DataEntryReorder',
    DeleteMemberData = 'DeleteMemberData',
    DeleteOwnData = 'DeleteOwnData',
    EBCoachingScheduleEditCancel = 'EBCoachingScheduleEditCancel',
    EBCoachingTherapistCorrespondence = 'EBCoachingTherapistCorrespondence',
    EBCoachingView = 'EBCoachingView',
    FileCabinetCompanyCopyMoveFile = 'FileCabinetCompanyCopyMoveFile',
    FileCabinetCompanyDeleteFile = 'FileCabinetCompanyDeleteFile',
    FileCabinetCompanyDownloadFile = 'FileCabinetCompanyDownloadFile',
    FileCabinetCompanyEditFolder = 'FileCabinetCompanyEditFolder',
    FileCabinetCompanyUploadFile = 'FileCabinetCompanyUploadFile',
    FileCabinetCompanyViewFolder = 'FileCabinetCompanyViewFolder',
    FileCabinetDelete = 'FileCabinetDelete',
    FileCabinetDownload = 'FileCabinetDownload',
    FileCabinetFolders = 'FileCabinetFolders',
    FileCabinetMove = 'FileCabinetMove',
    FileCabinetRename = 'FileCabinetRename',
    FileCabinetSharedFolderOnly = 'FileCabinetSharedFolderOnly',
    FileCabinetUpload = 'FileCabinetUpload',
    FileCabinetView = 'FileCabinetView',
    FunderReportDelete = 'FunderReportDelete',
    FunderReportEdit = 'FunderReportEdit',
    FunderReportView = 'FunderReportView',
    GoalLibraryAddAll = 'GoalLibraryAddAll',
    GoalLibraryAddCustom = 'GoalLibraryAddCustom',
    GoalLibraryDeleteAll = 'GoalLibraryDeleteAll',
    GoalLibraryDeleteCustom = 'GoalLibraryDeleteCustom',
    GoalLibraryView = 'GoalLibraryView',
    MakeDataDecisions = 'MakeDataDecisions',
    MedicalNecessityCreate = 'MedicalNecessityCreate',
    MedicalNecessityDelete = 'MedicalNecessityDelete',
    MedicalNecessityView = 'MedicalNecessityView',
    NYCInvoiceScrubber = 'NYCInvoiceScrubber',
    NYCInvoicing = 'NYCInvoicing',
    ProviderAccountEdit = 'ProviderAccountEdit',
    ProviderAccountView = 'ProviderAccountView',
    RemoveStaffFromClient = 'RemoveStaffFromClient',
    ReportDashboardAuthorization = 'ReportDashboardAuthorization',
    ReportDashboardBilling = 'ReportDashboardBilling',
    ReportDashboardClient = 'ReportDashboardClient',
    ReportDashboardFunderServices = 'ReportDashboardFunderServices',
    ReportDashboardParentVerification = 'ReportDashboardParentVerification',
    ReportDashboardPayroll = 'ReportDashboardPayroll',
    ReportDashboardScheduling = 'ReportDashboardScheduling',
    ReportDashboardStaff = 'ReportDashboardStaff',
    ReportDashboardStaffCredential = 'ReportDashboardStaffCredential',
    ReportsAddEdit = 'ReportsAddEdit',
    ReportsView = 'ReportsView',
    RequestRemoteParentSignature = 'RequestRemoteParentSignature',
    ResourceTrainingView = 'ResourceTrainingView',
    SchedulerAccessSessionNoteDrafts = 'SchedulerAccessSessionNoteDrafts',
    SchedulerAddAppointments = 'SchedulerAddAppointments',
    SchedulerAdminVerify = 'SchedulerAdminVerify',
    SchedulerApproveEVVAppointments = 'SchedulerApproveEVVAppointments',
    SchedulerBulkVerify = 'SchedulerBulkVerify',
    SchedulerDeleteAppointments = 'SchedulerDeleteAppointments',
    SchedulerViewAuditTrail = 'SchedulerViewAuditTrail',
    SchedulerDeleteVerifiedAppointments = 'SchedulerDeleteVerifiedAppointments',
    SchedulerEditAppointments = 'SchedulerEditAppointments',
    SchedulerEditVerifiedAppointments = 'SchedulerEditVerifiedAppointments',
    SchedulerOverrideClockInOutEVVAppointments = 'SchedulerOverrideClockInOutEVVAppointments',
    SchedulerVerifyAppointments = 'SchedulerVerifyAppointments',
    SchedulerViewAppointments = 'SchedulerViewAppointments',
    SchedulerViewCalendar = 'SchedulerViewCalendar',
    SchedulerViewsApptList = 'SchedulerViewsApptList',
    SchedulerGlobalLock = 'SchedulerGlobalLock',
    SchedulerViewsGroupCalendar = 'SchedulerViewsGroupCalendar',
    SchedulerViewsIndividual = 'SchedulerViewsIndividual',
    SchedulerViewVerifyAddress = 'SchedulerViewVerifyAddress',
    StaffAccessAllLocations = 'StaffAccessAllLocations',
    StaffAccessUserLocation = 'StaffAccessUserLocation',
    StaffAccountAdd = 'StaffAccountAdd',
    StaffAccountDismissCredentialWarnings = 'StaffAccountDismissCredentialWarnings',
    StaffAccountEdit = 'StaffAccountEdit',
    StaffAccountEditCredentials = 'StaffAccountEditCredentials',
    StaffAccountEditPayroll = 'StaffAccountEditPayroll',
    StaffAccountView = 'StaffAccountView',
    StaffAccountViewCredentials = 'StaffAccountViewCredentials',
    StaffAccountViewPayroll = 'StaffAccountViewPayroll',
    StaffFileCabinetDelete = 'StaffFileCabinetDelete',
    StaffFileCabinetDownload = 'StaffFileCabinetDownload',
    StaffFileCabinetFolders = 'StaffFileCabinetFolders',
    StaffFileCabinetFoldersAll = 'StaffFileCabinetFoldersAll',
    StaffFileCabinetMove = 'StaffFileCabinetMove',
    StaffFileCabinetRename = 'StaffFileCabinetRename',
    StaffFileCabinetUpload = 'StaffFileCabinetUpload',
    StaffFileCabinetView = 'StaffFileCabinetView',
    StaffNotesAddEdit = 'StaffNotesAddEdit',
    StaffNotesDelete = 'StaffNotesDelete',
    StaffNotesView = 'StaffNotesView',
    StaffOwmAccountViewOnly = 'StaffOwmAccountViewOnly',
    StaffPermissionsEdit = 'StaffPermissionsEdit',
    StaffLoggingInteractionsPolicyEdit = 'StaffLoggingInteractionsPolicyEdit',
    StudyArea = 'StudyArea',
    TreatmentGoalsEdit = 'TreatmentGoalsEdit',
    TreatmentGoalsView = 'TreatmentGoalsView',
    UnmasterSkillGoalsAndTargets = 'UnmasterSkillGoalsAndTargets',
    ViewContentAdd = 'ViewContentAdd',
    ViewContentView = 'ViewContentView',
    ViewEditAvailability = 'ViewEditAvailability',
    ViewEditReminders = 'ViewEditReminders',
    ResourcesView = 'ResourcesView'
}