import { AccountPermissions } from "@core/enums/account";
import { environment } from "src/environments/environment";

export const SIDEMENUS = [
    {
        Name: 'Claims',
        Description: 'View, create, edit billing encounters',
        Icon: 'person',
        IconTop: 6,
        path: 'billing/claims',
        permissionKeys: [AccountPermissions.BillingView],
        featureKeys: ['showBilling'],
        isNewVersion: true,
        Show: true,
        leftSideIcon: "billing-icon",
        subItems: [
        ]

    },
    {
        Name: 'Payment Posting',
        Description: 'View, create, edit payments',
        Icon: 'person',
        IconTop: 6,
        path: 'billing/paymentposting',
        permissionKeys: [AccountPermissions.BillingViewPayments],
        featureKeys: ['showBilling'],
        isNewVersion: true,
        Show: true,
        leftSideIcon: "billing-icon",
        subItems: [
        ]
    }
];